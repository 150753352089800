<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ml-2 mt-2"
        fab
        dark
        x-small
        color="#f5222d"
        v-bind="attrs"
        v-on="on"
        @click="deleteData(id)"
      >
        <v-icon> fas fa-trash-alt </v-icon>
      </v-btn>
    </template>
    <span>Изтрий</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "DeleteButton",
  props: ["deleteAction", "fetchData", "id", "setErrors"],
  methods: {
    deleteData: function (id) {
      let vm = this;

      vm.$confirm({
        title: "Сигурен ли си, че искаш да изтриеш този запис?",
        content: "Tова дейтсвие не може да бъде отменено!",
        icon: "warning",
        okText: "Потвърди",
        cancelText: "Откажи",
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        onOk() {
          vm.execDelete(id);
        },
        onCancel() {},
      });
    },
    execDelete: function (id) {
      let vm = this;

      vm.$store
        .dispatch(vm.deleteAction, id)
        .then((data) => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message,
          });

          vm.fetchData();

          vm.$emit("response-errors", []);
        })
        .catch((errors) => {
          vm.$emit("response-errors", errors);
        });
    },
  },
};
</script>

<style scoped></style>
